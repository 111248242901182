import alfredHospitalLogo from '@/assets/logos/alfred-hospital.jpeg'
import australianVenueCompanyLogo from '@/assets/logos/australian-venue-company.jpeg'
import bankOfMelbourneLogo from '@/assets/logos/bank-of-melbourne.jpeg'
import colesExpressLogo from '@/assets/logos/coles-express.jpeg'
import colesSupermarketsLogo from '@/assets/logos/coles-supermarkets.jpeg'
import colesLiquorLogo from '@/assets/logos/coles-liquor.png'
import bankSALogo from '@/assets/logos/bank-sa.jpeg'
import charlesDarwinUniversityLogo from '@/assets/logos/charles-darwin-university.jpeg'
import darlingDownsHealthLogo from '@/assets/logos/darling-downs-health.jpeg'
import blank from '@/assets/logos/blank.png'
import frasersPropertyAustraliaLogo from '@/assets/logos/frasers-property-australia.jpeg'
import gilbarcoAustraliaPtyLtdLogo from '@/assets/logos/gilbarco-australia-pty-ltd.jpeg'
import goodmanLogo from '@/assets/logos/goodman.jpeg'
import melbourneFacilitiesManagementLogo from '@/assets/logos/melbourne-facilities-management.jpeg'
import monashLogo from '@/assets/logos/monash.jpeg'
import kmartLogo from '@/assets/logos/kmart.jpeg'
import lloydsBankLogo from '@/assets/logos/lloyds-bank.jpeg'
import myCarLogo from '@/assets/logos/mycar.jpeg'
import ntPowerWaterLogo from '@/assets/logos/powerwater.jpeg'
import officeworksLogo from '@/assets/logos/officeworks.jpeg'
import otrLogo from '@/assets/logos/otr.jpg'
import queenslandRailLogo from '@/assets/logos/queensland-rail.jpeg'
import stGeorgeBankLogo from '@/assets/logos/st-george-bank.jpeg'
import targetLogo from '@/assets/logos/target.jpeg'
import verifiedLogo from '@/assets/logos/verified.png'
import verifiedTestLogo from '@/assets/logos/verified-test.png'
import victoriaPoliceLogo from '@/assets/logos/victoria-police.jpeg'
import vivaEnergyRetailLogo from '@/assets/logos/viva-energy-retail.png'
import westpacLogo from '@/assets/logos/westpac.jpeg'
import hertzLogo from '@/assets/logos/hertz.jpeg'
import breesePittDixonLogo from '@/assets/logos/breese-pitt-dixon.png'

const customerLogoMap = new Map<string, string>([
  ['Alfred Hospital', alfredHospitalLogo],
  ['Australian Venue Company', australianVenueCompanyLogo],
  ['Bank of Melbourne', bankOfMelbourneLogo],
  ['Bank SA', bankSALogo],
  ['Breese Pitt Dixon', breesePittDixonLogo],
  ['Charles Darwin University', charlesDarwinUniversityLogo],
  ['Coles Express', colesExpressLogo],
  ['Coles Liquor Group', colesLiquorLogo],
  ['Coles Logistics', colesSupermarketsLogo],
  ['Coles Supermarkets', colesSupermarketsLogo],
  ['Corporate Bank', westpacLogo],
  ['Darling Downs Health', darlingDownsHealthLogo],
  [
    'Frasers Property Australia Commercial Portfolio',
    frasersPropertyAustraliaLogo,
  ],
  ['Gilbarco Australia Pty Ltd', gilbarcoAustraliaPtyLtdLogo],
  ['Goodman', goodmanLogo],
  ['Hertz NZ', hertzLogo],
  ['Hertz Corporate Office', hertzLogo],
  ['Hertz Australia', hertzLogo],
  ['Kmart', kmartLogo],
  ['Kmart NZ', kmartLogo],
  ['Kmart Supply Chain', kmartLogo],
  ['Lloyds Bank', lloydsBankLogo],
  ['Melbourne Facilities Management', melbourneFacilitiesManagementLogo],
  ['Monash Uni - Caulfield East Campus', monashLogo],
  ['Monash Uni - City Campus', monashLogo],
  ['Monash Uni - Clayton Campus', monashLogo],
  ['Monash Uni - DLP Sites', monashLogo],
  ['Monash Uni - Parkville Campus', monashLogo],
  ['Monash Uni - Peninsula Campus', monashLogo],
  ['mycar', myCarLogo],
  ['NT PowerWater', ntPowerWaterLogo],
  ['NT PowerWater - Barge Landings', ntPowerWaterLogo],
  ['NT PowerWater - Separate Accommodations', ntPowerWaterLogo],
  ['OTR', otrLogo],
  ['Officeworks', officeworksLogo],
  ['Queensland Rail', queenslandRailLogo],
  ['St George Bank', stGeorgeBankLogo],
  ['Target', targetLogo],
  ['Target Trial', targetLogo],
  ['Verified Basic Induction', verifiedLogo],
  ['Verified Cleaning', verifiedLogo],
  ['Verified Complex Induction', verifiedLogo],
  ['Verified Demo Induction', verifiedLogo],
  ['Verified Group', verifiedLogo],
  ['Verified Induct', verifiedLogo],
  ['Verified International', verifiedLogo],
  ['VERIFIED INTERNATIONAL', verifiedLogo],
  ['Verified Maintenance', verifiedLogo],
  ['Verified Training', verifiedLogo],
  ['Verified Visitor', verifiedTestLogo],
  ['Verified Work Order', verifiedLogo],
  ['Victoria Police', victoriaPoliceLogo],
  ['Viva Energy Retail', vivaEnergyRetailLogo],
  ['Westpac', westpacLogo],
  ['default', blank],
])

export default customerLogoMap
