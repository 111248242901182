<template>
  <div>
    <SiteBanner :site-address="siteAddressStatic" :site-name="siteNameStatic" />
    <div class="card space-y-6 pb-3">
      <div
        class="flex items-center justify-center space-x-3 rounded-t-md py-2"
        :class="{
          'bg-amber-500': isCheckedIn,
          'bg-red-600': !isCheckedIn,
        }"
      >
        <ExclamationTriangleIcon
          v-if="isCheckedIn"
          class="h-7 w-7 stroke-white"
        />
        <XCircleIcon v-else class="h-7 w-7 stroke-white" />
        <h1 class="text-lg text-white">
          {{ isCheckedIn ? 'Not On Site' : 'Access Denied' }}
        </h1>
      </div>
      <div class="px-4">
        <p class="text-center text-xl">
          The location of your device indicates you are
          {{ isCheckedIn ? 'no longer' : 'not' }} on site.
        </p>
        <p v-if="isCheckedIn" class="mb-4 text-center text-sm italic">
          This check out has been recorded as not on site.
        </p>
        <div class="my-4 w-full">
          <MapWithPin :center="center" :user-position="userPosition" />
        </div>
        <PrimaryButton
          :key="submitButtonKey"
          action="OK"
          identifier="OkBtn"
          @click.once="handleRoute"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import SiteBanner from '@/components/SiteBanner.vue'
  import resetStore from '@/components/use/resetStore'
  import { useRouter } from 'vue-router'
  import MapWithPin from '@/components/MapWithPin.vue'
  import { storeToRefs } from 'pinia'
  import { useSiteStore } from '@/storage/site'
  import { useUserPositionStore } from '@/storage/userPosition'
  import { useUserStore } from '@/storage/user'
  import {
    ExclamationTriangleIcon,
    XCircleIcon,
  } from '@heroicons/vue/24/outline'
  import { locationAudits } from '@/services/api/user'
  import {
    acquireRequiredData,
    postCheckoutData,
  } from '@/services/api/checkout'
  import Log from '@/utilities/Log'

  const {
    address,
    name,
    latitude,
    longitude,
    id: siteId,
  } = storeToRefs(useSiteStore())
  const { checkedInTime } = storeToRefs(useUserStore())
  const { position } = storeToRefs(useUserPositionStore())

  const siteNameStatic = name.value
  const siteAddressStatic = address.value
  const isCheckedIn = checkedInTime.value !== null

  const center = {
    lat: latitude.value,
    lng: longitude.value,
  }

  const userPosition = {
    lat: position.value.latitude,
    lng: position.value.longitude,
  }
  const submitButtonKey = ref(0)
  const router = useRouter()
  const action = isCheckedIn ? 'checked-out-flagged' : 'check-in-denied'

  locationAudits.store(siteId.value, position.value, action).then()

  if (isCheckedIn) {
    await postCheckoutData(acquireRequiredData(), false).then()
  }

  Log.storeReset('NotOnSite.vue', 'handleRoute')
  resetStore()

  async function handleRoute() {
    submitButtonKey.value++
    await router.replace({ name: 'login' })
  }
</script>
