<template>
  <div>
    <Combobox v-model="modelValue" as="div">
      <ComboboxLabel class="text block font-medium leading-6 text-gray-900">
        {{ label }}
      </ComboboxLabel>
      <div class="relative mt-2">
        <ComboboxInput
          class="w-full rounded-md border border-gray-300 bg-white px-2 py-3 text-gray-900 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm sm:leading-6"
          :options="options"
          :display-value="(option: any) => option?.name"
          :placeholder="placeholder"
          @change="updateQuery"
        />
        <ComboboxButton
          :class="[
            'absolute right-0 flex items-center rounded-r-md px-2 focus:outline-none',
            allowMultiSelection ? 'top-4' : 'inset-y-0',
          ]"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        <ul
          v-if="allowMultiSelection"
          id="combobox-multi-selection-tags"
          class="mt-2 flex flex-wrap"
        >
          <li
            v-for="option in selectedOptions"
            :key="'option-tag-' + option.id"
            class="my-2 mr-1 flex-initial"
          >
            <button
              class="flex items-center rounded-lg border-2 border-transparent bg-verified-blue bg-opacity-80 px-2 py-1 text-white"
              type="button"
              @click="removeTag(option)"
            >
              {{ option.name }}
              <XMarkIcon class="ml-2 h-4 w-4 text-white" />
            </button>
          </li>
        </ul>

        <ComboboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ComboboxOption
            v-for="option in filteredOptions"
            :key="option.id"
            v-slot="{ active, selected }"
            :value="option"
            as="template"
          >
            <li
              :class="[
                'relative cursor-default select-none py-2 pl-3 pr-9',
                active && !allowMultiSelection
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-900',
              ]"
            >
              <span
                :class="[
                  'block truncate',
                  selected && !allowMultiSelection && 'font-semibold',
                ]"
              >
                {{ option['name'] }}
              </span>

              <span
                v-if="selected && !allowMultiSelection"
                :class="[
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                  active ? 'text-white' : 'text-indigo-600',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </div>
    </Combobox>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
  import { computed, PropType, ref, watch } from 'vue'
  import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
  } from '@headlessui/vue'
  import { debounce } from '@/utilities/debounce'
  import { XMarkIcon } from '@heroicons/vue/24/outline'

  type OptionType = {
    id: number | string
    name: string
  }

  const props = defineProps({
    allowMultiSelection: {
      default: false,
      type: Boolean,
    },

    label: {
      default: '',
      type: String,
    },

    maxSelection: {
      default: 5,
      type: Number,
    },

    options: {
      required: true,
      type: Array as PropType<OptionType[]>,
    },

    placeholder: {
      default: '',
      type: String,
    },
  })

  const emits = defineEmits(['optionChanged', 'maxSelectionReached'])

  const query = ref('')
  const selectedOption = ref<OptionType | null>(null)
  const filteredOptions = ref(props.options)

  const selectedOptions = ref<OptionType[]>([])

  const debouncedFilter = debounce((query: string[]) => {
    filteredOptions.value = props.options.filter((option) => {
      return option?.name.toLowerCase().includes(query[0].toLowerCase())
    })
  }, 250)

  const modelValue = computed({
    get: () =>
      props.allowMultiSelection ? selectedOptions.value : selectedOption.value,
    set: (value: OptionType | OptionType[] | null) => {
      if (props.allowMultiSelection) {
        addSelectedToArray(value as OptionType)
      } else if (value !== null) {
        selectedOption.value = value as OptionType
      }
    },
  })

  watch(query, (newVal) => {
    debouncedFilter(newVal)

    if (newVal === '') {
      selectedOption.value = null
    }
  })

  function addSelectedToArray(selected: OptionType | null): void {
    if (selected === null) {
      return
    }

    selectedOptions.value?.push(selected)
    selectedOptions.value = [...new Set(selectedOptions.value)]
  }

  watch(selectedOption, () => {
    emits('optionChanged', selectedOption.value)
  })

  watch(selectedOptions, () => {
    query.value = ''
    emits('optionChanged', selectedOptions.value)
    emitMaxSelectionReached()
  })

  function emitMaxSelectionReached() {
    if (selectedOptions.value.length > props.maxSelection) {
      emits('maxSelectionReached', true)
    } else {
      emits('maxSelectionReached', false)
    }
  }

  function removeTag(option: OptionType) {
    selectedOptions.value = selectedOptions.value.filter(
      (item) => item.id !== option.id,
    )
  }

  function updateQuery(event: Event & { target: HTMLInputElement }) {
    query.value = event.target.value
  }
</script>
