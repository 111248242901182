<template>
  <BaseModal v-if="show" title="Checkout Confirmation" @close="handleCancel">
    <slot></slot>
    <div class="mt-6 flex flex-1">
      <PrimaryButton action="CANCEL" @click="handleCancel" />
      <SecondaryButton
        button-classes="bg-opacity-0 active:bg-opacity-20 text-verified-blue"
        action="CHECKOUT"
        @click="handleCheckoutConfirmed"
      />
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
  import SecondaryButton from '@/components/SecondaryButton.vue'
  import BaseModal from '@/components/BaseModal.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'

  defineProps({
    show: {
      required: true,
      type: Boolean,
    },
  })

  const emit = defineEmits(['confirmed', 'cancelled'])

  function handleCancel() {
    emit('cancelled')
  }

  function handleCheckoutConfirmed() {
    emit('confirmed')
  }
</script>
