import { RouteLocationNormalized } from 'vue-router'
import { useNativeScanStore } from '@/storage/nativeScan'
import { useUserLogin } from '@/storage/userLogin'
import { storeToRefs } from 'pinia'
import { useUserPositionStore } from '@/storage/userPosition'
import { useSiteStore } from '@/storage/site'
import { useUserStore } from '@/storage/user'
import { postLocation } from '@/services/api/locationRequested'

export const positionResolver = async (to: RouteLocationNormalized) => {
  if (!to.meta.requiresLocation) {
    return true
  }

  const { isVisitor } = storeToRefs(useUserLogin())
  const { selectionConfirmed } = storeToRefs(useSiteStore())
  const { isContractor } = storeToRefs(useUserStore())

  const visitorScanIn = isVisitor.value && useNativeScanStore().uuid
  const visitorNotScanIn = isVisitor.value && !useNativeScanStore().uuid
  const visitorPreciseLocationRequired =
    visitorNotScanIn && to.name === 'selectNearbySite'
  const preciseLocationRequired =
    visitorPreciseLocationRequired || isContractor.value

  try {
    const { setUserPosition } = useUserPositionStore()
    const { position } = storeToRefs(useUserPositionStore())

    await setUserPosition()

    if (position.value.accuracy > 250) {
      postLocation({ location: position.value }).then()
    }

    if (preciseLocationRequired && position.value.accuracy > 250) {
      return { name: 'helpLocationPermission' }
    }
    return true
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    if (visitorScanIn || (visitorNotScanIn && selectionConfirmed.value)) {
      return true
    }

    return { name: 'helpLocationPermission', query: { to: to.name as string } }
  }
}
