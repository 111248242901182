<template>
  <div>
    <TitleBanner title="My History" />
    <div class="mt-2 p-4">
      <BaseSearchBar
        :placeholder="!serviceDate ? 'Search by site or service' : ''"
        @search="searchQueryChanged"
      ></BaseSearchBar>
    </div>
    <div class="px-4">
      <div class="mt-6 flow-root">
        <ul class="-my-5 divide-y divide-gray-200">
          <li
            v-for="serviceRecord in serviceRecords"
            :key="serviceRecord.id"
            class="py-4"
          >
            <div class="grid grid-cols-5 gap-4">
              <div class="border-r-2">
                {{ serviceRecord.date }}
              </div>
              <div class="col-span-4">
                <p class="truncate">
                  Conf No:
                  <span class="font-bold">{{ serviceRecord.id }}</span>
                </p>
                <p class="truncate">
                  {{ serviceRecord.siteName }}
                </p>
                <p class="truncate text-sm">
                  {{ serviceRecord.serviceName }}
                  {{
                    serviceRecord.frequencyCodes
                      ? serviceRecord.frequencyCodes.join(' ')
                      : ''
                  }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="loading" class="pt-3 text-center">
          <span class="mx-auto block h-12 w-12 animate-spin-slow">
            <img
              id="contentIcon"
              src="../assets/black-spinner-transparent.png"
              class="h-full w-full text-blue-500"
              alt="icon"
            />
          </span>
          <span>Loading...</span>
        </div>
        <div v-if="noMore" class="py-3 text-center">
          There are no more service records.
        </div>
        <div v-if="noResult" class="py-3 text-center">
          There are no available service records.
        </div>
      </div>
    </div>
    <div
      class="fixed bottom-4 right-6 flex h-16 w-16 items-center justify-center rounded-full bg-blue-500 text-white"
      @click="toggleCalendar"
    >
      <CalendarIcon class="h-6 w-6" />
    </div>
    <div
      v-if="calendarShown"
      class="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
    >
      <VueDatePicker
        v-model="serviceDate"
        :enable-time-picker="false"
        format="dd-MM-yyyy"
        :max-date="new Date()"
        :action-row="{
          showNow: false,
          showPreview: false,
          showSelect: false,
          showCancel: false,
        }"
        inline
        auto-apply
        @update:model-value="dateSelected"
      />
    </div>
    <div v-if="serviceDate" class="absolute right-14 top-40 mt-1.5">
      <button
        type="button"
        class="m-1 flex h-8 items-center rounded-lg border-2 border-transparent bg-indigo-100 px-2 text-sm font-semibold text-blue-600 hover:bg-indigo-200 focus:border-indigo-600 focus:outline-none"
      >
        {{ serviceDate }}
        <XMarkIcon
          class="-mr-1 ml-1 h-4 w-4 text-gray-600 hover:text-indigo-600"
          @click.stop="removeDate"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import TitleBanner from '@/components/TitleBanner.vue'
  import BaseSearchBar from '@/components/BaseSearchBar.vue'
  import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
  import {
    controller as abortController,
    getServiceRecordByUser,
    ServiceRecord,
  } from '@/services/api/serviceRecord'
  import useScroll from '@/components/use/scroll'
  import { CalendarIcon, XMarkIcon } from '@heroicons/vue/24/outline'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import { storeToRefs } from 'pinia'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useUserStore } from '@/storage/user'
  import VueDatePicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import dayjs from 'dayjs'
  import { debounce } from '@/utilities/debounce'

  export default defineComponent({
    name: 'UserHistory',
    components: {
      BaseSearchBar,
      CalendarIcon,
      TitleBanner,
      VueDatePicker,
      XMarkIcon,
    },

    async setup() {
      const { id: userId, isUserStoreDefault } = storeToRefs(useUserStore())
      const { addScrollListener, removeScrollListener } = useScroll(
        loadMoreServiceRecords,
      )
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const calendarShown = ref(false)
      const serviceDate = ref<string | null>(null)
      const serviceRecords = ref<ServiceRecord[]>([])
      const loading = ref(false)
      const noMore = ref(false)
      const noResult = ref(false)
      const searchQuery = ref<string | null>(null)
      let page = 1

      onMounted(() => {
        addScrollListener()
      })

      onUnmounted(() => {
        removeScrollListener()
      })

      await loadServiceRecords(page)

      async function loadServiceRecords(page: number): Promise<void> {
        loading.value = true
        noMore.value = false
        noResult.value = false

        if (isUserStoreDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              userId: userId.value,
            },
            ['InvalidStore', 'FetchUserHistory'],
          )
        }

        const response = await getServiceRecordByUser(
          userId.value,
          page.toString(),
          searchQuery.value,
          serviceDate.value,
        )
        if (response) {
          if (response.length > 0) {
            serviceRecords.value.push(...response)
          }
          manageLoadingState(response)
        }
      }

      const queryServiceRecords = debounce(() => loadServiceRecords(page), 500)

      function manageLoadingState(data: ServiceRecord[]) {
        if (data.length === 0 && serviceRecords.value.length !== 0) {
          noMore.value = true
        } else if (data.length === 0 && serviceRecords.value.length === 0) {
          noResult.value = true
        }
        loading.value = false
      }

      async function searchQueryChanged(search: string): Promise<void> {
        prepareForFiltering()
        loading.value = true
        searchQuery.value = search ? search : null
        queryServiceRecords()
      }

      async function dateSelected(): Promise<void> {
        if (serviceDate.value) {
          serviceDate.value = dayjs(serviceDate.value).format('DD-MM-YYYY')
        }
        prepareForFiltering()
        calendarShown.value = false
        await loadServiceRecords(page)
      }

      function prepareForFiltering(): void {
        abortController.abort()
        page = 1
        serviceRecords.value = []
      }

      async function loadMoreServiceRecords(): Promise<void> {
        if (!loading.value && !noMore.value && !noResult.value) {
          page++
          await loadServiceRecords(page)
        }
      }

      function removeDate(): void {
        serviceDate.value = null
        dateSelected()
      }

      function toggleCalendar() {
        calendarShown.value = !calendarShown.value
      }

      return {
        calendarShown,
        dateSelected,
        loading,
        noMore,
        noResult,
        removeDate,
        searchQueryChanged,
        serviceDate,
        serviceRecords,
        toggleCalendar,
      }
    },
  })
</script>
