import Pusher, { Options as PusherOptions } from 'pusher-js'
import { authoriseChannel } from '@/services/api/channelAuthorisation'

export interface EchoBroadcasterOptions {
  broadcaster: 'pusher'
  client?: Pusher
  cluster?: string
  devMode: boolean
  encrypted: boolean
  key: string
  pusher: PusherOptions
}

const options: PusherOptions = {
  channelAuthorization: {
    customHandler: (params, callback) => authoriseChannel(params, callback),
    endpoint: import.meta.env.VITE_CAPTURE_API + '/broadcasting/auth',
    transport: 'ajax',
  },
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER || 'ap4',
}

export const echoBroadcaster: EchoBroadcasterOptions = {
  broadcaster: 'pusher',
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER || 'ap4',
  devMode: true,
  encrypted: true,
  key: import.meta.env.VITE_PUSHER_KEY || '',
  pusher: options,
}
