<template>
  <div>
    <LoadingView :is-loading="true" message="Checking out..." />
  </div>
</template>

<script setup lang="ts">
  import LoadingView from './LoadingView.vue'
  import { storeToRefs } from 'pinia'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'
  import {
    postCheckoutData,
    acquireRequiredData,
  } from '@/services/api/checkout'
  import resetStore from '@/components/use/resetStore'
  import Log from '@/utilities/Log'
  import dayjs from 'dayjs'

  checkout()

  async function checkout() {
    const response = await postCheckoutData(acquireRequiredData())

    if (response.code === 200) {
      const url = checkoutConfirmationRedirectUrl(window.location.origin)
      resetStore()
      Log.storeReset('CheckoutView.vue', 'confirmCheckout')
      window.location.replace(url)
    }
  }

  function checkoutConfirmationRedirectUrl(base: string): string {
    const { address, name } = storeToRefs(useSiteStore())
    const currentTimeDate = dayjs()
    const currentTime = currentTimeDate.format('h:mma')
    const currentDate = currentTimeDate.format('D MMMM YYYY')
    const userName = useUserStore().firstName
    const query = new URLSearchParams({
      address: address.value,
      date: currentDate,
      name: name.value,
      time: currentTime,
      userName: userName,
    }).toString()

    const searchParams = new URLSearchParams({
      query: btoa(query),
    }).toString()

    return new URL(`/checkout/thank-you?${searchParams}`, base).toString()
  }
</script>
