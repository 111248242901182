import NotOnSite from '@/views/NotOnSite.vue'

export default {
  component: NotOnSite,
  meta: {
    returnTo: '',
    showMenu: false,
  },
  name: 'notOnSite',
  path: '/notOnSite',
}
