import ContractorCompanySelection from '@/views/ContractorCompanySelection.vue'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserPositionStore } from '@/storage/userPosition'
import { useBypassStore } from '@/storage/bypass'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { isUserOnSite } = storeToRefs(useUserPositionStore())
  const { scanBypassed } = storeToRefs(useBypassStore())
  if (!scanBypassed.value && !isUserOnSite.value) {
    next({
      name: 'notOnSite',
    })
  } else {
    next()
  }
}

export default {
  beforeEnter: guard,
  component: ContractorCompanySelection,
  meta: {
    loadingMessage: 'Loading companies...',
    returnTo: 'login',
    showMenu: true,
  },
  name: 'contractorCompanySelection',
  path: '/contractorCompanySelection',
}
