<template>
  <Component
    :is="form"
    :accreditation="accreditation"
    :should-validate="shouldValidate"
    @validated="handleValidated"
    @can-save="handleCanSave"
    @doesnt-have-accreditation="handleNoAccreditation"
    @has-accreditation="bubbleUpHasAccreditationEvent"
  />
</template>

<script setup lang="ts">
  import { PropType } from 'vue'
  import { AccreditationType } from '@/services/api/accreditation'
  import VevoVisaForm from '@/components/thirdPartyAccreditation/VevoVisaForm.vue'
  import StandardAccreditationForm from '@/components/StandardAccreditationForm.vue'
  import AUDriversLicenceForm from '@/components/thirdPartyAccreditation/AUDriversLicenceForm.vue'
  import NZDriversLicenceForm from '@/components/thirdPartyAccreditation/NZDriversLicenceForm.vue'
  import LinkSafeRetryPage from '@/components/thirdPartyAccreditation/LinkSafeRetryPage.vue'

  const props = defineProps({
    accreditation: {
      required: true,
      type: Object as PropType<AccreditationType>,
    },
    shouldValidate: Boolean,
  })

  const emit = defineEmits([
    'canSave',
    'hasAccreditation',
    'doesntHaveAccreditation',
    'validated',
  ])

  function getForm() {
    switch (props.accreditation?.name) {
      case 'Vevo Visa Check':
        return VevoVisaForm
      case 'Australian Drivers Licence':
        return AUDriversLicenceForm
      case 'New Zealand Drivers Licence':
        return NZDriversLicenceForm
      case 'LinkSafe':
        return LinkSafeRetryPage
      case null:
      case '':
      case undefined:
        throw new Error('No accreditation')
      default:
        return StandardAccreditationForm
    }
  }

  const form = getForm()

  function bubbleUpHasAccreditationEvent(id: unknown) {
    if (typeof id === 'number' || (typeof id === 'string' && !!Number(id))) {
      emit('hasAccreditation', id)
    }
  }

  function handleValidated() {
    emit('validated')
  }

  function handleCanSave(canSave: boolean) {
    emit('canSave', canSave)
  }

  function handleNoAccreditation(id: number) {
    emit('doesntHaveAccreditation', id)
  }
</script>
