<template>
  <main>
    <SiteBanner :site-name="siteName" :site-address="siteAddress" />
    <section class="flex flex-col px-4">
      <h1 class="mt-4 text-center text-2xl">Permit Spot Check</h1>
      <FormGenerator
        class="mb-4"
        :schema="schema"
        @form-changed="formChangedEventHandler"
        @visibility-changed="updateVisibleComponentsEventHandler"
        @update-component-visibility="updateComponentVisibilityEventHandler"
      />
      <div class="ml-auto grid w-full grid-cols-2 gap-2 sm:max-w-xl">
        <SecondaryButton
          id="cancel-spot-check"
          action="CANCEL"
          @click="hideOverlay"
        />
        <PrimaryButton
          id="submit-spot-check"
          :key="submitButtonKey"
          action="SAVE"
          @click.once="submitForm"
        />
      </div>
    </section>
    <LoadingView :is-loading="isLoading" :message="loadingMessage" />
  </main>
</template>

<script setup lang="ts">
  import SiteBanner from '@/components/SiteBanner.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import {
    Answer,
    checkAnswers,
    errorMessage,
    FormGenerator,
    ImageAnswer,
    SchemaType,
    scrollToFirstError,
    updateComponentVisibility,
    updateVisibleComponents,
    validateFormSubmission,
  } from '@verifiedit/form-generator'
  import { nextTick, reactive, ref } from 'vue'
  import LoadingView from '@/views/LoadingView.vue'
  import { getForm, submitSpotCheck } from '@/services/api/spotCheck'
  import { usePermitFormSubmissionsStore } from '@/storage/permitFormSubmissions'
  import RecoverableException from '@/exceptions/RecoverableException'
  import { useCustomerStore } from '@/storage/customer'
  import { storeToRefs } from 'pinia'
  import { useSiteStore } from '@/storage/site'
  import overlay from '@/components/use/overlay'
  import SecondaryButton from '@/components/SecondaryButton.vue'

  const schema = ref<SchemaType[]>([])

  const { name: siteName, address: siteAddress } = storeToRefs(useSiteStore())
  const { hideOverlay } = overlay()
  const isLoading = ref<boolean>(true)
  const loadingMessage = ref<string>('Loading spot check form...')
  const submitButtonKey = ref<number>(0)
  const visibleComponents = ref<string[]>([])
  const answers: Map<string, Answer> = reactive(new Map())

  await fetchForm()

  async function fetchForm() {
    const form = await getForm()

    schema.value = form.form.template.schema

    isLoading.value = false
  }

  function formChangedEventHandler(
    changedComponentName: string,
    userInput: string | (string | ImageAnswer)[],
  ) {
    checkAnswers(
      changedComponentName,
      userInput,
      schema.value as (SchemaType & errorMessage)[],
      answers,
    )
  }

  function updateVisibleComponentsEventHandler(
    components: Map<string, string>,
  ) {
    visibleComponents.value = updateVisibleComponents(components, answers)
  }

  function updateComponentVisibilityEventHandler(
    identifier: string,
    result: { items: string[]; value: boolean },
    key: string,
  ) {
    updateComponentVisibility(
      identifier,
      result,
      key,
      schema.value as (SchemaType & errorMessage)[],
    )
  }

  async function submitForm() {
    submitButtonKey.value++
    const permitFormSubmissionId = usePermitFormSubmissionsStore().id

    if (permitFormSubmissionId === null) {
      throw new RecoverableException('No permit has been completed')
    }

    const canSubmit = validateFormSubmission(
      schema.value as (SchemaType & errorMessage)[],
      answers,
      visibleComponents.value,
    )
    if (!canSubmit) {
      await nextTick()
      scrollToFirstError()
      return
    }

    loadingMessage.value = 'Saving spot check...'
    isLoading.value = true

    await submitSpotCheck(
      useCustomerStore().id,
      permitFormSubmissionId,
      answers,
    )

    isLoading.value = false
    overlay().hideOverlay()
  }
</script>
