<template>
  <img
    :src="verifiedLogo"
    :alt="alternateText"
    :width="width"
    :height="height"
    @click="handleLogout"
  />
</template>

<script lang="ts">
  import Logo from '@/assets/verified_logo_white.png'
  import { defineComponent } from 'vue'
  import { logout as forceLogout } from '@/services/api/logout'
  import { debug } from '@/config/debug'
  import router from '@/router'
  import resetStore from '@/components/use/resetStore'
  import Log from '@/utilities/Log'

  export default defineComponent({
    name: 'VerifiedLogo',
    props: {
      alternateText: {
        type: String,
        default: 'Verified',
      },

      height: {
        type: Number,
        default: 100,
      },

      width: {
        type: Number,
        default: 770,
      },
    },

    setup() {
      const logout = async () => {
        await forceLogout()

        resetStore()
        Log.storeReset('VerifiedLogo.vue', 'logout')

        await router.replace({ name: 'login' })
      }

      function handleLogout() {
        if (debug) {
          logout()
        }
      }

      return {
        handleLogout,
        verifiedLogo: Logo,
      }
    },
  })
</script>

<style scoped>
  img {
    @apply mx-auto;
  }
</style>
