import axios from 'axios'
import { storeToRefs } from 'pinia'
import { useSiteStore } from '@/storage/site'
import { useUserPositionStore } from '@/storage/userPosition'
import { useUserLogin } from '@/storage/userLogin'
import InvalidStoreException from '@/exceptions/InvalidStoreException'
import { useFingerprintStore } from '@/storage/fingerprint'
import { useUserStore } from '@/storage/user'

export type CheckoutResponse = {
  code: number
  message: string
}

type CheckoutData = {
  siteId: number
  location: Position
}

export const postCheckoutData = async (
  checkoutData,
  failedRequirements = false,
): Promise<CheckoutResponse> => {
  const config = {}
  if (failedRequirements) {
    Object.assign(config, { params: { failedRequirements: true } })
  }
  const response = await axios.post(
    `/api/sites/${checkoutData.siteId}/checkout`,
    {
      location: checkoutData.location,
    },
    config,
  )
  return response?.data?.data
}

export function acquireRequiredData(): CheckoutData {
  const { isSiteStoreDefault, id: siteId } = storeToRefs(useSiteStore())
  const { isUserPositionEmpty, position } = storeToRefs(useUserPositionStore())

  if (
    isSiteStoreDefault.value ||
    (!useUserLogin().isVisitor && isUserPositionEmpty.value)
  ) {
    throw new InvalidStoreException(
      {
        fingerprint: useFingerprintStore().fingerprint,
        siteId: siteId.value,
        userId: useUserStore().id,
        userPosition: position.value,
      },
      ['InvalidStore', 'StoreUserCheckout'],
    )
  }

  return {
    location: {
      accuracy: position.value.accuracy,
      altitude: position.value.altitude,
      latitude: position.value.latitude,
      longitude: position.value.longitude,
    },

    siteId: siteId.value,
  }
}
