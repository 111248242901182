<template>
  <div class="relative">
    <label
      v-if="inputValue"
      :class="{ 'text-blue-400': isValid, error: !isValid }"
      class="absolute left-2 top-1 text-xs"
      >{{ label }}
    </label>
    <input
      :value="inputValue"
      :class="[
        identifier,
        inputClasses,
        {
          error: !isValid,
          'pt-4': inputValue,
          'focus:ring-red-500': !isValid,
          'focus:ring-blue-400': isValid,
        },
      ]"
      :placeholder="label"
      :required="required"
      :type="type"
      :name="identifier"
      :inputmode="inputMode"
      :pattern="pattern"
      :maxlength="maxlength"
      :disabled="disabled"
      :readonly="readOnly"
      class="h-12 w-full rounded-md border border-gray-300 px-2 py-1 text-gray-800 outline-none duration-300 focus:ring-2 disabled:opacity-50"
      autocomplete="off"
      @keypress="handleKeyPress"
      @input="changeValue"
      @focus="handleFocus"
    />
    <slot></slot>
  </div>
</template>

<script lang="ts">
  import { defineComponent, HTMLAttributes, PropType } from 'vue'

  type AllowedInputValueType = string | number | null

  export default defineComponent({
    name: 'BaseInput',

    props: {
      disabled: {
        type: Boolean,
        default: false,
      },

      identifier: {
        type: String,
        default: '',
      },

      inputClasses: {
        type: Array,
        default: () => [],
      },

      inputMode: {
        type: String as PropType<HTMLAttributes['inputmode']>,
        default: 'text',
      },

      inputValue: {
        type: [String, Number] as PropType<AllowedInputValueType>,
        default: '',
      },

      isValid: {
        type: Boolean,
        default: false,
      },

      label: {
        type: String,
        default: '',
      },

      maxlength: {
        type: Number,
        default: Number.POSITIVE_INFINITY,
      },

      pattern: {
        type: String,
        default: '',
      },

      readOnly: {
        type: Boolean,
        default: false,
      },

      required: {
        type: Boolean,
        default: false,
      },

      type: {
        type: String,
        default: 'text',
      },
    },

    emits: ['keyPressed', 'update:inputValue', 'focused'],

    setup(props, { emit }) {
      function changeValue(event: Event | null): void {
        emit('update:inputValue', (event?.target as HTMLInputElement)?.value)
      }

      function handleKeyPress(event: KeyboardEvent) {
        emit('keyPressed', event)
      }

      function handleFocus(event: FocusEvent) {
        emit('focused', event)
      }

      return {
        changeValue,
        handleFocus,
        handleKeyPress,
      }
    },
  })
</script>
