<template>
  <li class="w-full">
    <button
      type="button"
      :aria-label="`Select menu option: ${label}`"
      class="inline-flex w-full items-center py-4"
      tabindex="0"
      @click="handleOptionClicked"
    >
      <slot></slot>
      <span class="break-words text-start">{{ label }}</span>
    </button>
  </li>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'HamburgerMenuItem',

    props: {
      label: {
        type: String,
        default: '',
      },
    },

    emits: ['optionClicked'],

    setup(props, { emit }) {
      function handleOptionClicked() {
        emit('optionClicked')
      }

      return {
        handleOptionClicked,
      }
    },
  })
</script>
