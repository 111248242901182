<template>
  <div>
    <SiteBanner :site-name="siteName" :site-address="siteAddress" />
    <WorkOrderDetails v-if="hasSelectedWorkOrder" class="mt-4" />
    <div class="mb-20 ml-auto mr-auto mt-4 w-11/12">
      <FormGenerator
        :key="formGeneratorKey"
        :schema="schema"
        @form-changed="formChangedEventHandler"
        @visibility-changed="updateVisibleComponentsEventHandler"
        @update-component-visibility="updateComponentVisibilityEventHandler"
        @persist-photo="storePhotoEventHandler"
        @remove-photo="removePhotoEventHandler"
      />
      <PrimaryButton
        :key="submitButtonKey"
        action="Submit"
        class="mt-4"
        type="submit"
        :disabled="isLoading"
        @click.once="submitForm"
      />
    </div>
    <LoadingView :is-loading="isLoading" :message="loadingMessage" />
    <PopulatePermitDataConfirmation
      :show="shouldPopulateSavedFormData"
      @populate="populatePermitFormData"
      @remove="clearPermitAnswers"
    />
    <ScrollToTop />
  </div>
</template>

<script setup lang="ts">
  import SiteBanner from '@/components/SiteBanner.vue'
  import {
    Answer,
    checkAnswers,
    errorMessage,
    FormGenerator,
    ImageAnswer,
    removePhoto,
    SchemaType,
    scrollToFirstError,
    storePhoto,
    updateComponentVisibility,
    updateVisibleComponents,
    validateFormSubmission,
  } from '@verifiedit/form-generator'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import { computed, nextTick, reactive, ref } from 'vue'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import { getImages, removeImage, storeImage } from '@/services/api/image'
  import { useRoute, useRouter } from 'vue-router'
  import {
    fetchPermitForms,
    PermitForm,
    submitPermitForm,
  } from '@/services/api/permit'
  import LoadingView from '@/views/LoadingView.vue'
  import PopulatePermitDataConfirmation from '@/components/PopulatePermitDataConfirmation.vue'
  import { useUserLogin } from '@/storage/userLogin'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { usePermitFormStore } from '@/storage/permitForm'
  import { useSiteStore } from '@/storage/site'
  import { useWorkOrderStore } from '@/storage/workOrder'
  import { useUserStore } from '@/storage/user'
  import WorkOrderDetails from '@/components/WorkOrderDetails.vue'
  import { useUserVisitorCompaniesStore } from '@/storage/userVisitorCompanies'
  import { usePermitFormSubmissionsStore } from '@/storage/permitFormSubmissions'
  import ScrollToTop from '@/components/ScrollToTop.vue'

  const { isVisitor } = storeToRefs(useUserLogin())
  const {
    address: siteAddress,
    name: siteName,
    id: siteId,
    isSiteStoreDefault,
  } = storeToRefs(useSiteStore())
  const { id: customerId, isCustomerStoreDefault } =
    storeToRefs(useCustomerStore())
  const {
    id: userId,
    userType,
    isUserStoreDefault,
    isUserTypeDefault,
    selectedContractorCompanyName,
  } = storeToRefs(useUserStore())
  const { lastSelected } = storeToRefs(useUserVisitorCompaniesStore())
  const {
    setStartPermit,
    setEndPermit,
    setEndPermitId,
    setStartPermitId,
    setPermitAnswers,
  } = usePermitFormStore()
  const {
    endPermit,
    getPermitAnswers,
    startPermit,
    startPermitId,
    endPermitId,
    hasEndPermit,
    hasSubmittedStartPermit,
  } = storeToRefs(usePermitFormStore())
  const { fingerprint } = storeToRefs(useFingerprintStore())
  const { hasSelectedWorkOrder } = storeToRefs(useWorkOrderStore())
  const { populateWorkOrderData } = useWorkOrderStore()
  const { setId } = usePermitFormSubmissionsStore()

  const router = useRouter()
  const schema = ref<(SchemaType & errorMessage)[]>([])
  const answers: Map<string, Answer> = reactive(new Map())
  const visibleComponents = ref<string[]>([])
  const submitButtonKey = ref<number>(0)
  const isLoading = ref<boolean>(true)
  const loadingMessage = ref<string>('Loading permit form...')
  const isEndPermit = useRoute().path === '/permit-form/end'
  const savedAnswers = getPermitAnswers.value

  const shouldPopulateSavedFormData = computed(
    () =>
      schema.value.length !== 0 &&
      hasSubmittedStartPermit.value &&
      !isEndPermit,
  )
  const formGeneratorKey = ref(0)

  try {
    schema.value = await fetchForms()
    populateWorkOrderData(schema.value as (SchemaType & errorMessage)[])
    isLoading.value = false
  } catch (e) {
    isLoading.value = false
    throw e
  }

  async function populateSavedDataOnForm() {
    if (!shouldPopulateSavedFormData.value) {
      return
    }

    for (let i = 0; i < schema.value.length; i++) {
      const component = schema.value[i]
      const answer = savedAnswers.get(component.identifier)
      if (answer !== undefined) {
        if (component.component === 'PhotoInput') {
          isLoading.value = true
          component.images = await getImages(answer as number[])
          isLoading.value = false
        } else {
          component.default = answer
        }
      }
      component.errorMessage = ''
    }
  }

  async function fetchForms(): Promise<(SchemaType & errorMessage)[]> {
    if (isEndPermit && hasEndPermit.value) {
      return deepClone(endPermit.value)
    } else if (startPermit.value.length !== 0) {
      return deepClone(startPermit.value)
    }

    if (isCustomerStoreDefault.value || isUserTypeDefault.value) {
      throw new InvalidStoreException(
        {
          customerId: customerId.value,
          userId: userId.value,
          userTypeId: userType.value.id,
        },
        ['InvalidStore', 'FetchPermitForms'],
      )
    }

    const forms = await fetchPermitForms(customerId.value, userType.value.id)

    if (forms.length === 0) {
      await routeToNext()
    }

    savePermitForms(forms)

    return deepClone(startPermit.value)
  }

  function savePermitForms(forms: PermitForm[]) {
    forms.reduce(
      (accumulator, permit) => {
        if (permit.type === 'START') {
          accumulator.start = permit.form.template.schema
          setStartPermitId(permit.id)
          setStartPermit(permit.form.template.schema)

          return accumulator
        }
        accumulator.end = permit.form.template.schema
        setEndPermitId(permit.id)
        setEndPermit(permit.form.template.schema)

        return accumulator
      },
      { end: [] as SchemaType[], start: [] as SchemaType[] },
    )
  }

  function formChangedEventHandler(
    changedComponentName: string,
    userInput: string | (string | ImageAnswer)[],
  ) {
    checkAnswers(
      changedComponentName,
      userInput,
      schema.value as (SchemaType & errorMessage)[],
      answers,
    )
  }

  function updateVisibleComponentsEventHandler(
    components: Map<string, string>,
  ) {
    visibleComponents.value = updateVisibleComponents(components, answers)
  }

  function updateComponentVisibilityEventHandler(
    identifier: string,
    result: { items: string[]; value: boolean },
    key: string,
  ) {
    updateComponentVisibility(
      identifier,
      result,
      key,
      schema.value as (SchemaType & errorMessage)[],
    )
  }

  async function storePhotoEventHandler(
    imageData: string,
    imageTypeId: number,
    sequenceOfImage: number,
    identifier: string,
  ) {
    if (
      isCustomerStoreDefault.value ||
      isSiteStoreDefault.value ||
      isUserStoreDefault.value
    ) {
      throw new InvalidStoreException(
        {
          customerId: customerId.value,
          fingerprint: fingerprint.value,
          siteId: siteId.value,
          userId: userId.value,
        },
        ['InvalidStore', 'StoreFormPhotoUpload'],
      )
    }

    loadingMessage.value = 'Saving image...'
    isLoading.value = true
    await storePhoto(
      imageData,
      imageTypeId,
      sequenceOfImage,
      identifier,
      schema.value as (SchemaType & errorMessage)[],
      () =>
        storeImage(
          customerId.value,
          siteId.value,
          userId.value,
          imageData,
          imageTypeId,
          sequenceOfImage,
        ),
    )
    isLoading.value = false
  }

  async function removePhotoEventHandler(imageId: string, identifier: string) {
    loadingMessage.value = 'Removing image...'
    isLoading.value = true
    await removePhoto(
      imageId,
      identifier,
      schema.value as (SchemaType & errorMessage)[],
      answers,
      removeImage,
    )
    isLoading.value = false
  }

  async function routeToNext() {
    if (isVisitor.value) {
      await router.replace({ name: 'readyToCheckout' })
    } else {
      await router.replace({ name: 'serviceSelection' })
    }
  }

  function savePermitFormDataToStore() {
    if (answers.has('workOrder')) {
      answers.delete('workOrder')
    }

    if (answers.has('workDescription')) {
      answers.delete('workDescription')
    }

    setPermitAnswers(Object.fromEntries(answers))
  }

  async function submitForm() {
    submitButtonKey.value++

    const canSubmit = validateFormSubmission(
      schema.value as (SchemaType & errorMessage)[],
      answers,
      visibleComponents.value,
    )
    if (!canSubmit) {
      await nextTick()
      scrollToFirstError()
      return
    }

    const currentPermitId = isEndPermit
      ? endPermitId.value
      : startPermitId.value

    if (
      isCustomerStoreDefault.value ||
      isSiteStoreDefault.value ||
      currentPermitId === null
    ) {
      throw new InvalidStoreException(
        {
          customerId: customerId.value,
          permitId: currentPermitId,
          siteId: siteId.value,
          userId: userId.value,
        },
        ['InvalidStore', 'StorePermitForm'],
      )
    }

    loadingMessage.value = 'Saving permit...'
    isLoading.value = true

    const contractorCompanyName = selectedContractorCompanyName.value
      ? selectedContractorCompanyName.value
      : lastSelected.value

    const response = await submitPermitForm(
      contractorCompanyName,
      customerId.value,
      siteId.value,
      currentPermitId,
      answers,
    )

    setId(response.id)

    if (isEndPermit) {
      setEndPermit([])
      await router.replace({ name: 'checkout' })
      return
    }
    savePermitFormDataToStore()
    await routeToNext()
    isLoading.value = false
  }

  function deepClone(object: SchemaType[]) {
    return JSON.parse(JSON.stringify(object))
  }

  async function populatePermitFormData() {
    await populateSavedDataOnForm()
    formGeneratorKey.value++
  }

  function clearPermitAnswers() {
    setPermitAnswers({})
  }
</script>
