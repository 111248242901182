import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/routes'
import { positionResolver, timezoneResolver } from '@/routes/resolvers'
import { noPrivateBrowsingGuard, supplementalFormGuard } from '@/routes/guards'
import setTQueryParam from '@/routes/guards/setTQueryParam'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(setTQueryParam)
router.beforeEach(positionResolver)
router.beforeResolve(timezoneResolver)
router.beforeEach(noPrivateBrowsingGuard)
router.beforeEach(supplementalFormGuard)

export default router
