<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10" @close="closeModal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative flex min-w-full transform flex-col rounded-lg bg-white text-left shadow-xl transition-all sm:my-8"
            >
              <div
                class="z-50 flex h-full gap-x-2 rounded-lg pl-2 shadow"
                :class="[statusColour]"
              >
                <div
                  class="flex w-4 items-center justify-center text-center text-xs"
                >
                  <div class="min-w-[120px] -rotate-90 text-white">
                    {{ statusText }}
                  </div>
                </div>
                <div
                  class="container-content flex flex-1 rounded-r-lg bg-white py-2 pr-2"
                >
                  <div class="m-2 flex-1">
                    <div
                      class="mb-2 flex flex-1 justify-between border-b border-gray-200"
                    >
                      <div class="flex-grow pb-1 text-lg font-bold">
                        Work Order: {{ workOrder.reference }}
                      </div>
                    </div>
                    <p
                      :class="[
                        'mb-4 overflow-y-auto',
                        {
                          'line-clamp-6': showLineClamp,
                          'h-[143px]': !showLineClamp,
                        },
                      ]"
                      @scroll="disableLineClamp"
                    >
                      {{ workOrder.description }}
                    </p>
                    <div
                      class="mb-2 flex-grow text-sm text-gray-700 opacity-50"
                    >
                      Created: {{ workOrder.createdAt }}
                    </div>
                    <div class="flex gap-x-4">
                      <SecondaryButton
                        aria-label="Cancel"
                        action="CANCEL"
                        @click="closeModal"
                      />
                      <PrimaryButton
                        aria-label="Start"
                        :action="beginWorkOrderText"
                        @click="startWorkOrder"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import { computed, PropType, ref } from 'vue'
  import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue'
  import SecondaryButton from '@/components/SecondaryButton.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import { WorkOrder } from '@/services/api/workOrder'

  const props = defineProps({
    statusColour: {
      default: '',
      type: String,
    },

    statusText: {
      default: '',
      type: String,
    },

    workOrder: {
      default: null,
      type: Object as PropType<WorkOrder>,
    },
  })

  const emit = defineEmits(['close', 'startWorkOrder'])

  const open = ref(true)

  const beginWorkOrderText = computed(() =>
    props.workOrder?.status === 'IN_PROGRESS' ? 'RESUME' : 'START',
  )
  const showLineClamp = ref(true)

  function startWorkOrder() {
    emit('startWorkOrder', props.workOrder as WorkOrder)
    closeModal()
  }

  function closeModal() {
    emit('close')
  }

  function disableLineClamp() {
    showLineClamp.value = false
  }
</script>
