import axios from 'axios'
import { UserType } from '@/services/api/customer'

export type ContractorCompany = {
  contractorVendors: {
    allDefect: boolean
    canSkipServices: boolean
    contractorCompanyId: number
    contractorVendorId: number
    defectEmail: string | null
    vendorName: string
    verifiedCustomerId: number
  }[]
  id: number
  name: string
  userTypes: UserType[]
}

export type User = {
  contractorCompanies: ContractorCompany[]
  firstname: string
  id: number
  isVisitor: boolean
  lastname: string
  name: string
  phone: string
}

export type MessageResponse = {
  code: number
  message: string
}

export type AuthenticatedUser = User & {
  contractorEmployee: {
    employeePhoto: string | null
    id: number
  }
}

const fetchUserByPhone = async (phone: string): Promise<User> => {
  const response = await axios.get('/api/users/' + phone)

  return response.data.data
}

export default fetchUserByPhone

export const pinReset = async (phone: string): Promise<MessageResponse> => {
  const response = await axios.post('/api/forgot-pin', {
    phone: phone,
  })

  return response.data.data
}

export const authenticateUser = async (
  phone: string,
  pin: string,
): Promise<AuthenticatedUser> => {
  const response = await axios.post('/api/auth/login', {
    phone: phone,
    pin: pin,
  })

  return response.data.data
}

export const changePin = async (newPin: string): Promise<MessageResponse> => {
  const response = await axios.post('/api/auth/change-pin', {
    pin: newPin,
  })

  return response?.data.data
}

export const checkPin = async (pin: string): Promise<MessageResponse> => {
  const response = await axios.post('/api/auth/check-pin', {
    pin,
  })

  return response.data?.data
}

export const locationAudits = {
  store: async (
    siteId: number,
    position: Position,
    action: string,
  ): Promise<string | void> => {
    try {
      const response = await axios.post(`/api/user-location-audits`, {
        action,
        location: position,
        siteId,
      })

      return response?.data?.data
    } catch {
      // fail silently
    }
  },
}
