<template>
  <div>
    <TitleBanner title="Change PIN" />
    <form class="mt-2 p-4" @submit.prevent>
      <div class="mb-4 rounded-md border border-gray-300 p-0">
        <PhoneNumberInput
          :initial-phone-number="phoneNumber"
          :default-country-code="countryCode"
          :disabled="true"
        />
      </div>
      <div class="mb-4">
        <PinInput
          label="Current Pin"
          :pin-input-changed="pins.currentPin.value"
          :pin-validation="currentPinIsValid"
          autofocus
          @value-changed="validateCurrentPin"
        />
        <div class="input-error error mt-1 pl-2">
          {{ pins.currentPin.errorMessage }}
        </div>
      </div>
      <div class="mb-4">
        <PinInput
          autocomplete="new-password"
          label="New Pin"
          :pin-input-changed="pins.newPin.value"
          :pin-validation="newPinIsValid"
          @value-changed="validateNewPin"
        />
        <div class="input-error error mt-1 pl-2">
          {{ pins.newPin.errorMessage }}
        </div>
      </div>
      <div class="mb-4">
        <PinInput
          autocomplete="new-password"
          label="Re-enter New Pin"
          :pin-input-changed="pins.reEnteredNewPin.value"
          :pin-validation="newPinIsValid"
          @value-changed="validateNewPinConfirmation"
        />
        <div class="input-error error mt-1 pl-2">
          {{ pins.reEnteredNewPin.errorMessage }}
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <SecondaryButton
          action="CANCEL"
          identifier="cancel-pin-reset"
          @click="hideOverlay"
        />
        <PrimaryButton
          :key="confirmChangeKey"
          action="SAVE"
          type="submit"
          identifier="save-new-pin"
          @click.once="confirmChange"
        />
      </div>
      <div
        v-if="showSuccessMessage"
        class="fixed bottom-0 left-0 right-0 top-0 flex h-screen w-full flex-wrap content-center justify-center bg-gray-400 bg-opacity-60"
      >
        <div
          class="grid w-9/12 grid-cols-2 gap-2 rounded-sm bg-white p-4 text-center transition duration-300"
        >
          <span class="col-span-2 m-0 mb-4 block w-full p-0">
            You have successfully changed your pin.
          </span>
          <button
            type="button"
            class="col-start-2 rounded-sm bg-blue-500 py-1"
            @click="hideOverlay"
          >
            OK
          </button>
        </div>
      </div>
      <LoadingView :is-loading="isLoading" message="Changing PIN..." />
    </form>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, nextTick, onMounted, ref } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import PhoneNumberInput from '@/components/PhoneNumberInput.vue'
  import PinInput from '@/components/PinInput.vue'
  import useCountry from '@/components/use/countries'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import { useRouter } from 'vue-router'
  import { changePin, checkPin } from '@/services/api/user'
  import LoadingView from '@/views/LoadingView.vue'
  import setFocus from '@/components/helpers/setFocus'
  import overlay from '@/components/use/overlay'
  import SecondaryButton from '@/components/SecondaryButton.vue'
  import { useUserLogin } from '@/storage/userLogin'
  import { storeToRefs } from 'pinia'

  export default defineComponent({
    name: 'ChangePin',
    components: {
      LoadingView,
      PhoneNumberInput,
      PinInput,
      PrimaryButton,
      SecondaryButton,
      TitleBanner,
    },

    setup() {
      const { getCountryByInternationalPhoneNumber } = useCountry()
      const { phoneNumber } = storeToRefs(useUserLogin())
      const countryCode = getCountryByInternationalPhoneNumber(
        phoneNumber.value,
      )?.value.countryCode

      const pins = ref<{ [key: string]: { [key: string]: string } }>({
        currentPin: {
          errorMessage: '',
          value: '',
        },
        newPin: {
          errorMessage: '',
          value: '',
        },
        reEnteredNewPin: {
          errorMessage: '',
          value: '',
        },
      })

      const currentPinIsValid = computed(
        () => pins.value.currentPin.errorMessage === '',
      )
      const newPinIsValid = computed(
        () => pins.value.newPin.errorMessage === '',
      )
      const router = useRouter()
      const showSuccessMessage = ref(false)
      const isLoading = ref(false)
      const confirmChangeKey = ref(0)

      onMounted(async () => {
        await nextTick()
        setFocus()
      })

      function validateEnteredPin(pinEntered: string, fieldName: string): void {
        const lengthOfPin = pinEntered.length
        const areNumbers = /^\d+$/
        if (lengthOfPin !== 4) {
          pins.value[fieldName].errorMessage = '4 digits are required'
        } else if (lengthOfPin === 4 && !areNumbers.test(pinEntered)) {
          pins.value[fieldName].errorMessage = 'Please enter numbers only'
        } else if (lengthOfPin === 4 && areNumbers.test(pinEntered)) {
          pins.value[fieldName].errorMessage = ''
          pins.value[fieldName].value = pinEntered
        }
      }

      function confirmNewPin() {
        if (pins.value.newPin.value !== pins.value.reEnteredNewPin.value) {
          pins.value.newPin.errorMessage = "PIN doesn't match"
          pins.value.reEnteredNewPin.errorMessage = "PIN doesn't match"
          return false
        }
        return true
      }

      async function checkUserIdentity(pin: string) {
        const response = await checkPin(pin)

        if (response.code === 200) {
          return true
        }
        pins.value.currentPin.errorMessage = 'Current pin is invalid'

        return false
      }

      async function sendChangePinRequest(
        identityIsValid: boolean,
        newPinConfirmed: boolean,
      ) {
        if (!identityIsValid || !newPinConfirmed) {
          return
        }
        const response = await changePin(pins.value.newPin.value)
        if (response.code === 200) {
          showSuccessMessage.value = true
        }
      }

      async function confirmChange() {
        isLoading.value = true
        for (const pin of Object.values(pins.value)) {
          if (pin.errorMessage !== '') {
            isLoading.value = false
            confirmChangeKey.value += 1
            return
          }
        }
        const newPinConfirmed = confirmNewPin()
        const identityIsValid = await checkUserIdentity(
          pins.value.currentPin.value,
        )
        await sendChangePinRequest(identityIsValid, newPinConfirmed)
        isLoading.value = false
      }

      const { hideOverlay } = overlay()

      function validateNewPin(pin: string) {
        validateEnteredPin(pin, 'newPin')
      }

      function validateNewPinConfirmation(pin: string) {
        validateEnteredPin(pin, 'reEnteredNewPin')
      }

      function validateCurrentPin(pin: string) {
        validateEnteredPin(pin, 'currentPin')
      }

      return {
        confirmChange,
        confirmChangeKey,
        countryCode,
        currentPinIsValid,
        hideOverlay,
        isLoading,
        newPinIsValid,
        phoneNumber,
        pins,
        router,
        showSuccessMessage,
        validateCurrentPin,
        validateNewPin,
        validateNewPinConfirmation,
      }
    },
  })
</script>
