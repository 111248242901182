<script lang="ts">
  import { defineComponent, onMounted, ref, watch } from 'vue'
  import Echo from 'laravel-echo'
  import config from '@/config/index'
  import Pusher from 'pusher-js'
  import UserChannel from '@/channels/user'
  import userChannelListeners from '@/listeners/userChannelListeners'
  import handleError from '@/utilities/handleError'
  import ReportableException from '@/exceptions/ReportableException'
  import { storeToRefs } from 'pinia'
  import { useUserStore } from '@/storage/user'

  interface EchoType {
    Echo: Echo<'pusher'>
  }
  type CustomWindowType = typeof window & EchoType

  export default defineComponent({
    name: 'BroadcastManager',
    emits: ['socketReady'],

    setup(props, { emit }) {
      const echo = ref<Echo<'pusher'>>()
      const { isLoggedIn, id: userId } = storeToRefs(useUserStore())

      onMounted(() => {
        // retrieve the connection to channels after refresh page
        if (isLoggedIn.value) {
          initialiseEchoAndPusher()
          bindChannels()
        }
      })

      watch(isLoggedIn, (isLoggedIn, prevLoginState) => {
        // establish the connection to channels after login
        if (isLoggedIn !== prevLoginState && isLoggedIn) {
          initialiseEchoAndPusher()
          bindChannels()
        }

        if (isLoggedIn !== prevLoginState && !isLoggedIn) {
          disconnectChannels()
        }
      })

      function initialiseEchoAndPusher() {
        const windowObj = window as CustomWindowType

        if (config.debug) {
          Pusher.logToConsole = true
        }

        if (windowObj.Echo) {
          echo.value = windowObj.Echo
          return
        }

        const options = config.echoBroadcaster
        options.client = new Pusher(options.key, options.pusher)
        windowObj.Echo = new Echo(options)

        echo.value = windowObj.Echo
        echo.value?.connector.pusher.connection.bind(
          'error',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          function (err: any) {
            if (err?.error?.data?.message?.includes('Invalid signature')) {
              handleError(
                new ReportableException(
                  'Failed to connect to websocket',
                  {
                    error: JSON.stringify(err),
                  },
                  ['PusherError'],
                ),
              )
            }
          },
        )
        echo.value?.connector.pusher.connection.bind('connected', () => {
          emitSocketId()
        })
      }

      function emitSocketId() {
        emit('socketReady', echo.value?.socketId())
      }

      function disconnectChannels() {
        echo.value?.leave(`user.${userId.value}`)
        echo.value?.connector.pusher.disconnect()
      }

      function bindChannels() {
        const userChannel = new UserChannel(echo.value)
        userChannel.join(`user.${userId.value}`)
        userChannel.attachListeners(userChannelListeners)
      }

      return () => null
    },
  })
</script>
