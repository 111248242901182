<template>
  <div>
    <TitleBanner title="Who are you representing today" />
    <div class="my-5 space-y-5 p-3">
      <BaseInput
        v-model:input-value="companyName"
        input-mode="text"
        identifier="visitor-access-representation-company"
        label="Company Name"
        :required="true"
        :is-valid="errorMessage.companyName === ''"
        @focused="clearCompanyName"
      >
        <div
          v-show="showCompanyNameList && visitorCompanyNames.length !== 0"
          class="visitorCompanyNameList absolute z-20 w-full bg-white"
        >
          <span
            v-for="name in visitorCompanyNames"
            :key="visitorCompanyNames.indexOf(name)"
            class="block border px-2 py-3"
            @click="companySelected"
          >
            {{ name }}
          </span>
        </div>
      </BaseInput>
      <span v-if="errorMessage.companyName" class="error m-1 !my-0 block">
        {{ errorMessage.companyName }}
      </span>
      <BaseSelector
        :options="userTypeList"
        :select-element-style="`block border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:outline-none py-3 rounded-md w-full text-gray-600 ${
          selectedUserType.id > 0 ? 'pt-5' : ''
        }`"
        label="I am a ..."
        :label-classes="
          selectedUserType.id > 0
            ? ['text-blue-400 absolute left-2 text-xs top-1']
            : ['text-gray-600 absolute left-2.5 top-3']
        "
        :should-auto-select="true"
        data-test="visitor-type-list"
        @option-changed="userTypeSelected"
      ></BaseSelector>
      <span v-if="errorMessage.userType" class="error m-1 !my-0 block">
        {{ errorMessage.userType }}
      </span>
      <PrimaryButton
        :key="continueButton"
        identifier="visitor-access-representation-continue"
        type="submit"
        action="Continue"
        @click.once="continueButtonClicked"
      />
    </div>
    <LoadingView :is-loading="isLoading" message="Setting selections..." />
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, ref, watch } from 'vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import BaseInput from '@/components/BaseInput.vue'
  import BaseSelector from '@/components/BaseSelector.vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import {
    getCustomerVisitorUserTypes,
    UserType,
  } from '@/services/api/customer'
  import { onBeforeRouteLeave, useRouter } from 'vue-router'
  import { visitorCheckin } from '@/services/api/siteSelected'
  import LoadingView from '@/views/LoadingView.vue'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import { resetDataStores } from '@/components/use/resetStore'
  import { useUserLogin } from '@/storage/userLogin'
  import { useCustomerStore } from '@/storage/customer'
  import { storeToRefs } from 'pinia'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useUserPositionStore } from '@/storage/userPosition'
  import { useSessionStore } from '@/storage/session'
  import { useSiteStore } from '@/storage/site'
  import { useUserVisitorCompaniesStore } from '@/storage/userVisitorCompanies'
  import { useUserStore } from '@/storage/user'

  type FormFieldName = 'companyName' | 'userType'

  export default defineComponent({
    name: 'VisitorAccessRepresentation',

    components: {
      BaseInput,
      BaseSelector,
      LoadingView,
      PrimaryButton,
      TitleBanner,
    },

    async setup() {
      const { setUserType, setCheckedInTime } = useUserStore()
      const { id: userId } = storeToRefs(useUserStore())
      const { setUserLoginIsVisitor } = useUserLogin()
      const { setLastUsedCompany, setVisitorCompanyName } =
        useUserVisitorCompaniesStore()
      const { names, lastSelected } = storeToRefs(
        useUserVisitorCompaniesStore(),
      )
      const router = useRouter()
      const { id: siteId, isSiteStoreDefault } = storeToRefs(useSiteStore())
      const { position } = storeToRefs(useUserPositionStore())
      const { id: customerId, isCustomerStoreDefault } =
        storeToRefs(useCustomerStore())
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const { setSiteSessionId, setSessionTtl } = useSessionStore()

      const companyName = ref(lastSelected.value)
      const visitorCompanyNames = ref<string[]>(names.value)
      const selectedUserType = ref<UserType>({ id: 0, name: '' })
      const continueButton = ref(0)
      const userTypeList = ref<UserType[] | []>([])
      const showCompanyNameList = ref(false)
      const isLoading = ref(false)
      const errorMessage = reactive({
        companyName: '',
        userType: '',
      })

      onBeforeRouteLeave((to) => {
        if (to.name === 'checkWorkOrder') {
          resetDataStores()
        }
      })

      watch(companyName, (newValue) => {
        showErrorOnEmptyField('companyName', companyName.value)
        if (!visitorCompanyNames.value.includes(newValue)) {
          showCompanyNameList.value = true
        }
        companyNameChanged(newValue)
      })

      watch(selectedUserType, () =>
        showErrorOnEmptyField('userType', selectedUserType.value.id),
      )

      await fetchUserType()

      async function fetchUserType() {
        if (isCustomerStoreDefault.value) {
          throw new InvalidStoreException(
            {
              customer: customerId.value,
              fingerprint: fingerprint.value,
              siteId: siteId.value,
              userId: userId.value,
            },
            ['InvalidStore', 'FetchVisitorUserTypes'],
          )
        }
        userTypeList.value = await getCustomerVisitorUserTypes(customerId.value)
      }

      async function continueButtonClicked() {
        showErrorOnEmptyField('companyName', companyName.value)
        showErrorOnEmptyField('userType', selectedUserType.value.id)

        if (!canContinue()) {
          continueButton.value += 1
          return
        }

        isLoading.value = true
        setUserType(selectedUserType.value)
        setLastUsedCompany(companyName.value)
        setVisitorCompanyName(companyName.value)
        setUserLoginIsVisitor(true)

        if (isSiteStoreDefault.value) {
          throw new InvalidStoreException(
            {
              companyName: companyName.value,
              fingerprint: fingerprint.value,
              selectedUserType: selectedUserType.value,
              siteId: siteId.value,
              userId: userId.value,
            },
            ['InvalidStore', 'StoreVisitorCheckin'],
          )
        }

        const response = await visitorCheckin(
          siteId.value,
          companyName.value,
          selectedUserType.value.id,
          position.value,
        )

        if (response.code === 200) {
          setCheckedInTime(response.checkedInAt)
          setSessionTtl(response.sessionTtl)
          setSiteSessionId(response.siteSessionId)
          await router.replace({ name: 'checkWorkOrder' })
        }

        isLoading.value = false
      }

      function canContinue() {
        return errorMessage.companyName === '' && errorMessage.userType === ''
      }

      function userTypeSelected(userTypeId: number) {
        selectedUserType.value = (userTypeList.value as UserType[]).find(
          (userType) => userType.id === userTypeId,
        ) ?? { id: 0, name: '' }
      }

      function companySelected(event) {
        companyName.value = event.target.innerText
        showCompanyNameList.value = false
      }

      function showErrorOnEmptyField(
        fieldName: FormFieldName,
        fieldValue: string | number | undefined,
      ) {
        if (fieldValue) {
          errorMessage[fieldName] = ''
        } else {
          errorMessage[fieldName] = 'Field cannot be empty'
        }
      }

      function companyNameChanged(change: string) {
        visitorCompanyNames.value = names.value.filter((name: string) =>
          name.toLowerCase().startsWith(change.toLowerCase()),
        )
      }

      function clearCompanyName() {
        companyName.value = ''
      }

      return {
        clearCompanyName,
        companyName,
        companySelected,
        continueButton,
        continueButtonClicked,
        errorMessage,
        isLoading,
        selectedUserType,
        showCompanyNameList,
        userTypeList,
        userTypeSelected,
        visitorCompanyNames,
      }
    },
  })
</script>
