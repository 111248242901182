<template>
  <main class="page-container">
    <TitleBanner
      class="header"
      title="The following accreditation(s) or licence(s) are required to access the site"
    />
    <div class="flex-container">
      <div class="content mx-4 my-8 justify-center space-y-6 sm:mx-8">
        <p>Do you have the following?</p>
        <ol data-test="accreditation-forms" class="space-y-6">
          <li v-for="accreditation in accreditations" :key="accreditation.id">
            <AccreditationForm
              :accreditation="accreditation"
              :should-validate="shouldValidate"
              @validated="handleValidatedEvent"
              @can-save="addUserAccreditationToRequest"
              @doesnt-have-accreditation="removeFromRequests"
              @has-accreditation="handleHasAccreditation"
            />
          </li>
        </ol>
        <div>
          <PrimaryButton
            :key="continueButtonKey"
            class="mb-16"
            identifier="accreditation-form-continue"
            action="Continue"
            :disabled="buttonDisabled"
            @click.once="validateAccreditations"
          />
        </div>
      </div>
    </div>
    <LoadingView :is-loading="isLoading" message="Saving accreditations..." />
  </main>
</template>

<script setup lang="ts">
  import AccreditationForm from '@/components/AccreditationForm.vue'
  import { computed, ref } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import {
    AccreditationCheckType,
    AccreditationType,
    getAccreditationWithUserAccreditations,
    storeUserAccreditation,
    updateUserAccreditation,
    UserAccreditationRequestType,
  } from '@/services/api/accreditation'
  import { useRouter } from 'vue-router'
  import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'
  import ReportableException from '@/exceptions/ReportableException'
  import LoadingView from '@/views/LoadingView.vue'
  import { getSiteEntryCheck } from '@/components/use/siteEntryConfirmation'

  const router = useRouter()
  const continueButtonKey = ref<number>(0)
  const fail = 'Fail'
  const stopWorking = 'Stop Working'
  const accreditationChecks = getAccreditationChecks()
  const accreditations = ref<AccreditationType[]>([])
  const buttonDisabled = computed(
    () => accreditationsActioned.value.length !== accreditations.value?.length,
  )
  const accreditationsActioned = ref<
    { accreditationId: number; hasAccreditation: boolean }[]
  >([])
  const isLoading = ref<boolean>(false)
  const shouldValidate = ref(false)
  const userAccreditationRequests = ref<UserAccreditationRequestType[]>([])

  await fetchAccreditations()

  function addUserAccreditationToRequest(data: {
    canSave: boolean
    userAccreditation: UserAccreditationRequestType
  }) {
    if (!data.canSave) {
      return
    }

    const userAccreditation = data.userAccreditation
    const existsInArray = userAccreditationRequests.value.find(
      (request) =>
        request.accreditationId === userAccreditation.accreditationId,
    )

    if (!existsInArray) {
      userAccreditationRequests.value.push(userAccreditation)
    }

    if (!isLoading.value) {
      postUserAccreditations()
    }
  }

  async function postUserAccreditations() {
    const amountOfUserAccreditationsToSend =
      accreditationsActioned.value.filter(
        (action) => action.hasAccreditation === true,
      ).length

    if (
      amountOfUserAccreditationsToSend !==
      userAccreditationRequests.value.length
    ) {
      return
    }

    try {
      isLoading.value = true

      await Promise.allSettled(
        userAccreditationRequests.value.map((request) =>
          request.userAccreditationId
            ? updateUserAccreditation(request)
            : storeUserAccreditation(request),
        ),
      )

      await navigateToAccreditationCheckPage()
    } catch (error: unknown) {
      throw new ReportableException(
        'Failed to save accreditations',
        { error: error },
        ['Accreditation'],
      )
    } finally {
      isLoading.value = false
    }
  }

  function getAccreditationChecks(): AccreditationCheckType[] {
    const accreditationChecks = getSiteEntryCheck('Accreditation')

    if (accreditationChecks === undefined || !accreditationChecks.checks) {
      throw new ReportableException(
        'Missing accreditation check result in site entry store',
        { siteEntryChecks: useSiteEntryConfirmationStore().siteEntryChecks },
      )
    }

    return accreditationChecks.checks.filter(
      (check) => check.result === stopWorking || check.result === fail,
    ) as AccreditationCheckType[]
  }

  function getAccreditationIds(accreditationChecks: AccreditationCheckType[]) {
    return accreditationChecks
      .filter((check) => check.updateAllowed)
      .reduce(
        (accumulator, check) => {
          accumulator.accreditationIds.push(check.accreditationId)
          accumulator.userAccreditationIds.push(check.userAccreditationId)

          return accumulator
        },
        {
          accreditationIds: [] as number[],
          userAccreditationIds: [] as number[],
        },
      )
  }

  async function fetchAccreditations() {
    if (accreditationChecks.length !== 0) {
      const { accreditationIds, userAccreditationIds } =
        getAccreditationIds(accreditationChecks)

      accreditations.value = await getAccreditationWithUserAccreditations(
        accreditationIds,
        userAccreditationIds,
      )
    }
  }

  function removeFromRequests(accreditationId: number) {
    addToActioned(accreditationId, false)

    const requestToRemove = userAccreditationRequests.value.find(
      (request) => request.accreditationId === accreditationId,
    )
    if (requestToRemove) {
      userAccreditationRequests.value.splice(
        userAccreditationRequests.value.indexOf(requestToRemove),
        1,
      )
    }
  }

  function addToActioned(accreditationId: number, hasAccreditation: boolean) {
    const accreditationAction = accreditationsActioned.value.find(
      (action) => action.accreditationId === accreditationId,
    )

    if (accreditationAction) {
      accreditationAction.hasAccreditation = hasAccreditation
    } else {
      accreditationsActioned.value.push({
        accreditationId: accreditationId,
        hasAccreditation: hasAccreditation,
      })
    }
  }

  async function validateAccreditations(): Promise<void> {
    continueButtonKey.value++
    shouldValidate.value = true

    if (areAllUserAccreditationsMissing()) {
      await navigateToAccreditationCheckPage()
    }
  }

  function areAllUserAccreditationsMissing(): boolean {
    return (
      accreditationsActioned.value.filter(
        (action) => action.hasAccreditation === false,
      ).length === accreditations.value.length
    )
  }

  async function navigateToAccreditationCheckPage(): Promise<void> {
    await router.replace({
      name: 'checkAccreditation',
      query: { finalCheck: '1' },
    })
  }

  function handleHasAccreditation(id: number) {
    addToActioned(id, true)
  }

  function handleValidatedEvent() {
    shouldValidate.value = false
  }
</script>
